import { Chip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { deleteMorePage } from "../../clients/services/sams/content/morePage";
import { MorePage } from "../../clients/services/sams/content/morePage/types";
import { MorePageType } from "../../clients/services/sams/types";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";

import { useListMorePages } from "./hooks/useListMorePages";

const MorePageTable = () => {
  const { isLoading, data, error, refetch } = useListMorePages();

  const getMorePageTypeChip = (
    params: GridRenderCellParams<any, MorePageType>
  ) => {
    switch (params.value) {
      case MorePageType.INTERNAL_PAGE:
        return (
          <Chip
            color="secondary"
            label="Internal Page"
            variant="filled"
            data-testid="morePageTypeTestIDInternalPage"
          />
        );
      case MorePageType.EXTERNAL_LINK:
        return (
          <Chip
            color="primary"
            label="External Link"
            variant="filled"
            data-testid="morePageTypeTestIDExternalLink"
          />
        );
      default:
        return <></>;
    }
  };

  const rows = data?.map((morePage) => {
    return {
      id: morePage.id,
      headline: morePage.headline,
      type: morePage.type,
      status: morePage.status
    };
  });

  const columns = [
    {
      field: "headline",
      headerName: "Tile Headline",
      flex: 1
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      maxWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, MorePageType>) =>
        getMorePageTypeChip(params)
    }
  ];

  return (
    <TableComponent<MorePage>
      isLoading={isLoading}
      error={error}
      refetch={refetch}
      rows={rows as Rows}
      columns={columns}
      testId="MorePageTestID"
      createButton={{
        link: "/create-more-page",
        text: "Create More Page"
      }}
      tableProperties={{
        itemName: "More Page",
        actionLabel: "More Page",
        specialUrl: "more-page"
      }}
      deleteFunction={deleteMorePage}
    />
  );
};

export default MorePageTable;
