import { AxiosResponse } from "axios";

import staticConfig from "../../../../../config/static";
import client from "../../../base";

import {
  ListMorePagesParams,
  MorePage,
  CreateOrUpdateMorePageResponse,
  CreateMorePageRequestBody
} from "./types";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const listMorePages = async (params?: ListMorePagesParams) => {
  const url = `${baseUrl}/content/more-pages`;

  const response: AxiosResponse<MorePage[]> = await client.get(url, {
    params
  });
  return response.data;
};

export const createMorePage = async (morePage: CreateMorePageRequestBody) => {
  const url = `${baseUrl}/content/more-pages`;

  const response: AxiosResponse<CreateOrUpdateMorePageResponse> =
    await client.post(url, morePage);
  return response.data;
};

export const getMorePage = async (id: string) => {
  const url = `${baseUrl}/content/more-pages/${id}`;

  const response: AxiosResponse<MorePage> = await client.get(url);
  return response.data;
};

export const updateMorePage = async (morePage: MorePage) => {
  const url = `${baseUrl}/content/more-pages/${morePage.id}`;

  const response: AxiosResponse<CreateOrUpdateMorePageResponse> =
    await client.put(url, morePage);
  return response.data;
};

export const deleteMorePage = async (id: string) => {
  const url = `${baseUrl}/content/more-pages/${id}`;

  await client.delete(url);
};
