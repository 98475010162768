import styled from "@emotion/styled";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  IosShare as ShareIcon
} from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, CircularProgress, TextField, Tooltip } from "@mui/material";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  downloadMarketingPermissionData,
  uploadBookingCodes
} from "../../clients/services/sams/promotion";
import { Status } from "../../clients/services/sams/types";
import {
  setNotification,
  setUploadCodesLargeRequestMessage
} from "../../state/reducers/notification";
import BookingCodesUpload from "../BookingCodesTable/BookingCodesUpload";
import ConfirmationDialog from "../ConfirmationDialog";
import DeleteBookingCodesTable from "../DeleteBookingCodesTable";
import { createAndShareMarketingDataSpreadsheet } from "../utils/createAndShareMarketingDataSpreadSheet";

export const ButtonLink = styled(Link)`
  text-decoration: none;
`;
export const StyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  min-width: auto;
  span {
    margin: 0 auto;
  }
  border-radius: 50%;
  &:hover {
    background-color: #c4d9f5;
  }
`;
const EmailInput = styled(TextField)`
  margin-bottom: 10px;
`;
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
`;
const WarningText = styled.p`
  font-weight: bold;
  margin-top: -15px;
`;

interface UpdateDeleteTableBtnInterface {
  id: any;
  deleteFunction?: (id: string) => Promise<void>;
  refetch: any;
  itemName: string;
  specialUrl?: string;
  showMarketingPermission?: boolean;
  status?: Status;
  bookingCodeButtons?: {
    upload: boolean;
    delete: boolean;
  };
}

const UpdateDeleteTableBtn: FC<UpdateDeleteTableBtnInterface> = ({
  id,
  deleteFunction,
  refetch,
  itemName,
  specialUrl,
  status,
  showMarketingPermission,
  bookingCodeButtons
}) => {
  const [emails, setEmails] = useState<string>("");
  const [bookingCodesCsvFile, setBookingCodesCsvFile] = useState<File | null>(
    null
  );
  const [isUploadingBookingCodes, setIsUploadingBookingCodes] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  let itemDisplayName = itemName.replace(/([A-Z])/, " $1").toLowerCase();
  if (itemDisplayName.charAt(0) === " ")
    itemDisplayName = itemDisplayName.substring(1);

  const handleShareMarketingData = async () => {
    const trimmedEmails = emails.split(",").map((email) => email.trim());

    if (!trimmedEmails[0]) {
      return dispatch(
        setNotification({
          message: "Please enter at least one email address.",
          severity: "error"
        })
      );
    }

    try {
      await createAndShareMarketingDataSpreadsheet(id, trimmedEmails);
      dispatch(
        setNotification({
          message: "Marketing data shared successfully!",
          severity: "success"
        })
      );
    } catch (_: unknown) {
      dispatch(
        setNotification({
          message: "Something went wrong while sharing marketing data.",
          severity: "error"
        })
      );
    }
  };

  const handleDownloadMarketingData = async () => {
    try {
      await downloadMarketingPermissionData(id);
    } catch (e: unknown) {
      dispatch(
        setNotification({
          message: `${(e as AxiosError).response?.data.errorMessage}`,
          severity: "error"
        })
      );
    }
  };

  const handleUploadBookingCodes = async () => {
    if (!bookingCodesCsvFile) {
      return;
    }

    setIsUploadingBookingCodes(true);

    try {
      const {
        data: { unsuccessfullyUploadedBCodesCount: duplicateCodeCount },
        headers
      } = await uploadBookingCodes(id, bookingCodesCsvFile);
      await refetch();

      if (headers["large-request"]) {
        dispatch(setUploadCodesLargeRequestMessage());
      } else if (duplicateCodeCount > 0) {
        dispatch(
          setNotification({
            message: `${duplicateCodeCount} booking ${
              duplicateCodeCount > 1 ? "codes were" : "code was"
            } duplicated and not uploaded.`,
            severity: "warning"
          })
        );
      } else {
        dispatch(
          setNotification({
            message: "Booking codes uploaded successfully!",
            severity: "success"
          })
        );
      }
    } catch (e: unknown) {
      const { errorCode, errorMessage } = (e as AxiosError).response?.data;
      const allCodesAlreadyExist = errorCode === "PRO-20690";

      dispatch(
        setNotification({
          message: allCodesAlreadyExist
            ? "All booking codes already exist."
            : errorMessage,
          severity: "error"
        })
      );
    }

    setIsUploadingBookingCodes(false);
    setBookingCodesCsvFile(null);
  };

  const handleDelete = async () => {
    try {
      await deleteFunction?.(id.toString());
      dispatch(
        setNotification({
          message: `${itemDisplayName
            .charAt(0)
            .toUpperCase()}${itemDisplayName.substring(
            1
          )} deleted successfully!`,
          severity: "success"
        })
      );
      await refetch();
    } catch (e: unknown) {
      dispatch(
        setNotification({
          message: `${(e as AxiosError).response?.data.errorMessage}`,
          severity: "error"
        })
      );
    }
  };

  return (
    <>
      {showMarketingPermission && (
        <ConfirmationDialog
          title={"Share marketing data"}
          description={
            "Please enter the email addresses of the recipients you would like to share the marketing data with. Separate multiple email addresses with a comma."
          }
          actionLabel={"Send"}
          action={handleShareMarketingData}
          extraContent={
            <EmailInput
              autoFocus
              id="name"
              label="Email Addresses"
              type="email"
              fullWidth
              variant="standard"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            />
          }
          testIdPrefix={`share-marketing-data-${id}`}
        >
          {(triggerAction) => (
            <Tooltip title="Share Marketing Data">
              <StyledButton
                startIcon={<ShareIcon />}
                size="medium"
                variant="text"
                onClick={triggerAction}
                data-testid={`share-marketing-permission-data-${id}-trigger`}
              ></StyledButton>
            </Tooltip>
          )}
        </ConfirmationDialog>
      )}

      {showMarketingPermission && (
        <Tooltip title="Download Marketing Data">
          <StyledButton
            startIcon={<DownloadIcon />}
            size="medium"
            variant="text"
            onClick={handleDownloadMarketingData}
            data-testid={`download-marketing-permission-data-${id}-trigger`}
          ></StyledButton>
        </Tooltip>
      )}

      {!bookingCodeButtons && (
        <ButtonLink
          to={
            specialUrl
              ? `/update-${specialUrl}/${id}`
              : `/update-${itemName}/${id}`
          }
          data-testid="UpdateDeleteTestID"
        >
          <Tooltip title="Edit">
            <StyledButton
              variant="text"
              startIcon={<EditIcon />}
              size="medium"
            ></StyledButton>
          </Tooltip>
        </ButtonLink>
      )}

      {bookingCodeButtons && (
        <ConfirmationDialog
          title={"Upload booking codes"}
          action={handleUploadBookingCodes}
          actionLabel={bookingCodesCsvFile ? "Upload" : undefined}
          extraContent={
            <BookingCodesUpload
              id={id}
              bookingCodesCsvFile={bookingCodesCsvFile}
              setBookingCodesCsvFile={setBookingCodesCsvFile}
            />
          }
          testIdPrefix={`upload-booking-codes-${id}`}
        >
          {(triggerAction) => (
            <Tooltip title="Upload booking codes">
              {!isUploadingBookingCodes ? (
                <StyledButton
                  startIcon={<ShareIcon />}
                  size="medium"
                  variant="text"
                  onClick={triggerAction}
                  data-testid={`upload-booking-codes-${id}-trigger`}
                ></StyledButton>
              ) : (
                <LoadingIndicatorWrapper>
                  <CircularProgress size={24} />
                </LoadingIndicatorWrapper>
              )}
            </Tooltip>
          )}
        </ConfirmationDialog>
      )}

      {bookingCodeButtons ? (
        <ConfirmationDialog
          title={"Delete booking codes"}
          extraContent={
            <DeleteBookingCodesTable
              promotionCode={id}
              refetchPromotions={refetch}
            />
          }
          testIdPrefix={`delete-booking-codes-method-${id}`}
        >
          {(triggerAction) => (
            <Tooltip title="Delete booking codes">
              <StyledButton
                startIcon={<DeleteIcon />}
                size="medium"
                variant="text"
                onClick={triggerAction}
                data-testid={`delete-booking-codes-method-${id}-trigger`}
                disabled={!bookingCodeButtons.delete}
              ></StyledButton>
            </Tooltip>
          )}
        </ConfirmationDialog>
      ) : (
        deleteFunction && (
          <ConfirmationDialog
            title={`Delete ${itemDisplayName}`}
            description={`Are you sure you would like to delete selected ${itemDisplayName}?`}
            extraContent={
              status === Status.PUBLISHED ? (
                <WarningText data-testid={`delete-warning-${id}`}>
                  ⚠️Warning: This {itemDisplayName} is Published
                </WarningText>
              ) : null
            }
            actionLabel={`Delete ${itemDisplayName}`}
            action={handleDelete}
            testIdPrefix={`delete-method-${id}`}
          >
            {(triggerAction) => (
              <Tooltip title="Delete">
                <StyledButton
                  startIcon={<DeleteIcon />}
                  size="medium"
                  variant="text"
                  onClick={triggerAction}
                  data-testid={`delete-method-${id}-trigger`}
                ></StyledButton>
              </Tooltip>
            )}
          </ConfirmationDialog>
        )
      )}
    </>
  );
};

export default UpdateDeleteTableBtn;
